<app-toolbar title="Untätig" [enableBackbutton]="false"></app-toolbar>
<ion-content>
  <ion-grid>
    <ion-row class="app-pool-container">
      <ion-col>
        <div>
          <div class="not-availiable-details-label">
            <strong>
              Sie sind schon zu lange untätig bitte klicken sie auf weiter um
              Fortzufahren. Ansonsten werden sie in {{ countDown | minuteSeconds }} ausgeloggt.
            </strong>
          </div>
          <ion-row class="ion-justify-content-center">
            <ion-col class="ion-text-center">
              <ion-button size="Large" shape="round" (click)="dismiss()"
                >Weiter</ion-button
              >
            </ion-col>
          </ion-row>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
