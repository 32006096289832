
<app-toolbar
title="Offline"
[enableBackbutton]="false"
></app-toolbar>
<ion-content>
  <ion-grid>
<ion-row class="app-pool-container">
  <ion-col> 
    <div>
      <div class="not-availiable-details-label">
       <strong> Sie sind offline, weitere Aktionen können nicht ausgeführt werden. 
         Wenn sie wieder online sind klicken sie auf Weiter.
       </strong>
      </div>
      <ion-row class="ion-justify-content-center">
        <ion-col class="ion-text-center">
          <ion-button
            size="Large"
            shape="round"
            [disabled]="!isConnected"
            (click)="dismiss()"
            >Weiter</ion-button
          >
        </ion-col>
      </ion-row>
    </div>
  </ion-col>
</ion-row>
</ion-grid>
</ion-content>
