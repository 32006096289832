<ion-toolbar>
  <ion-title size="large">{{ title }}</ion-title>
  <ion-buttons slot="start">
    <ion-back-button
      *ngIf="!isModal && !showMenuToggle && enableBackbutton"
      defaultHref=""
      color="light"
    ></ion-back-button>

    <ion-menu-button
      *ngIf="!isWideScreen && showMenuToggle && isPotrait"
      auto-hide="false"
    ></ion-menu-button>
  </ion-buttons>

  <ion-buttons
    *ngIf="isWideScreen || menuButtons.length === 1 || showAllButtons"
    margin-left
    slot="end"
    class="end-button"
  >
    <div class="toolbar-buttons" *ngFor="let button of menuButtons">
      <ion-button
        class="ion-text-center"
        size="large"
        [color]="button.color"
        fill="solid"
        shape="round"
        (click)="didClickMenuAction(button)"
        *ngIf="!button.showIconOnly"
        [disabled]="button.disabled"
      >
        <div class="toolbar-button-title" *ngIf="button.title">
          {{ button.title }}
        </div>
        <ion-icon
          *ngIf="button.icon"
          size="large"
          [name]="button.icon"
        ></ion-icon>
      </ion-button>

      <ion-button
        *ngIf="button.showIconOnly"
        icon-only
        (click)="didClickMenuAction(button)"
        [disabled]="button.disabled"
      >
        <ion-icon
          slot="end"
          size="large"
          [name]="button.icon"
          *ngIf="button.showIconOnly"
        ></ion-icon>
      </ion-button>
    </div>
  </ion-buttons>
  <ion-buttons *ngIf="!isWideScreen && menuButtons.length > 1 && !showAllButtons" slot="end">
    <ion-button slot="end" (click)="presentActionSheet(menuButtons)">
      <ion-icon slot="icon-only" name="ellipsis-vertical-outline"></ion-icon>
    </ion-button>
  </ion-buttons>
</ion-toolbar>
