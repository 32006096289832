import { Component, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Platform } from '@ionic/angular';

import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { LoginService } from 'src/app/services/login.service';
import { User } from '../model/user';
import { AuthService } from '../rest/auth.service';
import { MenuEntry } from '../../shared/menu-entry';
import { MenuHelper } from 'src/app/services/menu-helper.service';

@Component({
  selector: 'app-main-menu',
  templateUrl: 'main-menu.component.html',
  styleUrls: ['main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit, OnDestroy {
  public static Id = 'main-menu';

  @Input() public title: string;
  @Input() public subtitle: string;
  @Input() public icon: string;

  userDisplayName: string;
  currentRoute: string;
  version: string;

  get isWideScreen(): boolean {
    return this._isWideScreen;
  }
  get menuAlwaysVisible(): boolean {
    return this._menuAlwaysVisible;
  }
  get menu$(): Observable<MenuEntry[]> {
    return this._menu$.asObservable();
  }
  get user$() {
    return this.authService.user$;
  }
  get loginExpiresIn$() {
    return this.loginService.logoutTimer$;
  }

  public readonly breakWidth = 800;

  constructor(
    private ngZone: NgZone,
    private router: Router,
    public route: ActivatedRoute,
    private authService: AuthService,
    private loginService: LoginService,
    private menuHelper: MenuHelper,
    private platform: Platform
  ) {
    this._isTablet = this.platform.is('tablet');
  }

  ionViewDidEnter() {
    this._isWideScreen = window.innerWidth >= this.breakWidth;

    window.onresize = (e) => {
      this.ngZone.run(() => {
        this._isWideScreen = window.innerWidth >= this.breakWidth;
      });
    };
  }

  ionViewWillLeave() {
    delete window.onresize;
  }

  ngOnInit() {
    this._routerEventsSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
      }
    });

    this._menuSub = this.menu$.subscribe((menu) => {
      if (
        (!this.currentRoute ||
          this.currentRoute === '/' ||
          this.currentRoute === '') &&
        menu.length > 0
      ) {
        if (this._isTablet) {
          this.router.navigate(['/profile'], { replaceUrl: true });
        } else {
          this.router.navigate([menu[0].url], { replaceUrl: true });
        }
      }
    });

    this._userSub = this.authService.user$.subscribe((user) => {
      this.userDisplayName = user?.displayName ?? user?.username ?? '';
      this.updateMenu(user);
    });
  }

  ngOnDestroy() {
    this._userSub?.unsubscribe();
    this._menuSub?.unsubscribe();
    this._routerEventsSub?.unsubscribe();
  }

  onLogout() {
    return this.loginService.logout();
  }

  private updateMenu(user: User) {
    if (!user) {
      this._menu$.next([]);
      return;
    }

    const menu = this.menuHelper.createMenu(user);

    this._menu$.next(menu);
  }

  private _isWideScreen = true;
  private _menuAlwaysVisible = true;
  private _menu$: BehaviorSubject<MenuEntry[]> = new BehaviorSubject([]);
  private _userSub: Subscription;
  private _menuSub: Subscription;
  private _routerEventsSub: Subscription;
  private _isTablet: boolean = false;
}
