import { Component, OnInit } from '@angular/core';
import { NetworkHelperService } from '../../../services/network-helper.service';
import { BehaviorSubject } from 'rxjs';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-offline',
  templateUrl: './offline.component.html',
  styleUrls: ['./offline.component.scss'],
})
export class OfflineComponent implements OnInit {
  isConnected: Boolean = false
  constructor(private modalController: ModalController, private networkHelper: NetworkHelperService) { }

  ngOnInit() {
    this.networkHelper.getConnected().subscribe( isOnline => {
      this.isConnected = isOnline
    })
  }

  dismiss() {
    this.modalController.dismiss();
  }

}
