<ion-grid class="ion-no-padding">
  <form class="form" [formGroup]="form">
    <ion-row class="login-logo ion-align-items-center ion-padding">
      <ion-col class="ion-align-items-center">
        <ion-img src="../../../../assets/toolbar-logo.png"></ion-img>
      </ion-col>
      <ion-col class="ion-align-items-center">
        <ion-img src="../../../../assets/charite-logo.png"></ion-img>
      </ion-col>
      <ion-col class="ion-align-items-center">
        <ion-img src="../../../../assets/techniker-logo.PNG"></ion-img>
      </ion-col>
    </ion-row>
    <ion-row class="ion-align-items-center ion-padding">
      <ion-col class="userSettingsIdentifier" size="4">Nutzername</ion-col>
      <ion-col size="8">
        <ion-input type="text" class="whiteRoundBorders" placeholder="Nutzername" formControlName="username">
        </ion-input>
      </ion-col>
    </ion-row>
    <ion-row class="ion-align-items-center ion-padding">
      <ion-col class="userSettingsIdentifier" size="4">Password</ion-col>
      <ion-col size="8">
        <ion-input type="password" class="whiteRoundBorders" placeholder="Password" formControlName="password">
        </ion-input>
      </ion-col>
    </ion-row>
    <ion-row class="ion-align-items-center ion-padding">
      <ion-col class="ion-text-center">
        <app-form-messages [errors]="errors$ | async" [successMessages]="successMessages$ | async"
          [messages]="messages$ | async"></app-form-messages>
      </ion-col>
    </ion-row>
    <ion-row class="ion-align-items-center ion-padding">
      <ion-col class="ion-text-center">
        <ion-button shape="round" [disabled]="!form.valid" (click)="onLogin()">
          Anmelden
        </ion-button>
      </ion-col>
    </ion-row>
  </form>
</ion-grid>
