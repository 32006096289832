import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService, LogoutParamReason, LogoutParams } from '../core/rest/auth.service';
import { GlobalConstants } from '../shared/global.constants';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { User } from '../core/model/user';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  get user$(): Observable<User | null> {
    return this.authService.user$;
  }
  get loggedout$(): Observable<LogoutParams> {
    return this.authService.loggedout$;
  }
  get logoutTimer$(): Observable<number> {
    return this.authService.logoutTimer$;
  }
  public get authenticatedUser(): User {
    return this.authService.user;
  }

  get isUserAdmin() {
    return this.authService.isUserAdmin;
  }

  get isUserClinicLeader() {
    return this.authService.isUserClinicLeader;
  }

  get isUserDoctor() {
    return this.authService.isUserDoctor;
  }

  public get hasToken(): boolean {
    return this.authService.hasToken;
  }

  public get isAuthenticated(): boolean {
    const hasToken = this.hasToken;
    const user = this.authenticatedUser;
    return hasToken && !!user;
  }

  constructor(public modalController: ModalController, protected http: HttpClient, private authService: AuthService) { }

  public async logout() {
    return this.authService.logout();
  }

  public canActivate(route: ActivatedRouteSnapshot): boolean {
    const hasToken = this.hasToken;
    const user = (hasToken) ? this.authenticatedUser : null;
    if (!user) {
      return false;
    }

    let findPermission = false;
    for (let pagePermission of GlobalConstants.pagePermissions) {
      if (pagePermission.path === route.routeConfig.path) {
        findPermission = true;
        if (pagePermission.roles.includes(user.role)) {
          return true;
        }
      }
    }

    if (!findPermission) {
      console.error('AuthGuard', `permission for ${route.routeConfig.path} not found`);
    }

    return false;
  }

  public canVisitPage(router: Router, user?: User): boolean {
    return this.canVisitPageURL(router.url, user);
  }


  public canVisitPageURL(url: string, user?: User): boolean {
    const hasToken = this.hasToken;

    if (!user) {
      user = (hasToken) ? this.authenticatedUser : null;
    }
    if (!user) {
      return false
    }
    let findPermission = false;
    for (let pagePermission of GlobalConstants.pagePermissions) {
      if (pagePermission.menuEntry?.matchPath?.test(url)) {
        findPermission = true;
        if (pagePermission.roles.includes(user.role)) {
          return true;
        }
      }
    }



    if (!findPermission) {
      console.error('AuthGuard', `permission for ${url} not found`);
    }

    return false;
  }

  private _isLoginShown: boolean;
}
