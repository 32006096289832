import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { FormBuilder } from '@angular/forms';
import { MainMenuComponent } from './core/main-menu/main-menu.component';
import { SharedModule } from './shared/shared.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage-angular';
import { SecureStorage } from '@ionic-native/secure-storage/ngx';
import { AuthService } from './core/rest/auth.service';
import { CommonModule } from '@angular/common';
import { AppErrorHandler } from './shared/app-error.handler';
import { AppHttpInterceptor } from './services/app-http-interceptor.service';
import { LoginService } from './services/login.service';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { LoginPageModule } from './pages/login/login.module';
import { NetworkHelperService } from './services/network-helper.service';
import { Network } from '@ionic-native/network/ngx';
import { UserIdleModule } from 'angular-user-idle';
import { GlobalConstants } from './shared/global.constants';
import { TimeoutPageModule } from './pages/shared/timout/timeout.module';
import { DocumentViewer } from '@ionic-native/document-viewer/ngx';

@NgModule({
  declarations: [AppComponent, MainMenuComponent],
  entryComponents: [],
  imports: [
    CommonModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    TimeoutPageModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    SharedModule,
    HttpClientModule,
    UserIdleModule.forRoot(GlobalConstants.userIdleConfig),
    IonicStorageModule.forRoot()
  ],
  providers: [
    StatusBar,
    SplashScreen,
    FormBuilder,
    InAppBrowser,
    SecureStorage,
    AuthService,
    LoginService,
    LoginPageModule,
    AppVersion,
    Network,
    DocumentViewer,
    NetworkHelperService,
    {
      provide: RouteReuseStrategy, useClass: IonicRouteStrategy,
    },
    {
      provide: ErrorHandler, useClass: AppErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppHttpInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
