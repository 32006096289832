import { ErrorHandler } from "@angular/core";

export class AppErrorHandler implements ErrorHandler {

    constructor() { }

    handleError(error) {
        /// @TODO: better error handling and logging
        throw error;
    }
}