import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.page.html',
  styleUrls: ['./user-login.page.scss'],
})
export class UserLoginPage {
  @Input() isModal: boolean;
  @Input() extraMessage: string;
  @Input() redirectUri: string;

  constructor() { }
}
