import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControlOptions, FormBuilder, FormGroup } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { RestApiService } from '../rest/rest-api.service';

export abstract class BaseFormErrorHandling {
  get errors$(): Observable<string[]> {
    return this._errorsHandler$.asObservable();
  }

  get successMessages$(): Observable<string[]> {
    return this._successMessagesHandler$.asObservable();
  }

  get messages$(): Observable<string[]> {
    return this._messagesHandler$.asObservable();
  }

  constructor() {
  }

  handleHttpError(error: HttpErrorResponse) {
    if (error) {
      if (error.status === 401) {
        //if (error.error && error.error.errors) {
        //  this.errorsHandler.next(error.error.errors.map(error => error.title));
        //}
        throw error;
      }
      if (
        error.status >= 400 &&
        error.status <= 500 &&
        error.error &&
        error.error.errors
      ) {
        this.handleErrorMessages(error.error.errors.map((error) => error.title));
        return null;
      }
      console.error(error);
      /// @TODO: handle other error, too
      throw null;
    }
    return null;
  }

  handleErrorMessage(message: string) {
    if (message) {
      this._errorsHandler$.next([message]);
    }
  }
  handleErrorMessages(messages: string[]) {
    if (messages && messages.length > 0) {
      this._errorsHandler$.next(messages);
    }
  }

  handleSuccessMessage(message: string) {
    if (message) {
      this._successMessagesHandler$.next([message]);
    }
  }

  handleMessage(message: string) {
    if (message) {
      this._messagesHandler$.next([message]);
    }
  }
  handleMessages(messages: string[]) {
    if (messages && messages.length > 0) {
      this._messagesHandler$.next(messages);
    }
  }

  dismissModalOrSendSuccessMessage(modalController: ModalController, data?: any, successMessage?: string) {
    return modalController
      .getTop()
      .then(async (v) =>  v ? modalController.dismiss(data) : this.handleSuccessMessage(successMessage)
      ).catch((err) => {
        this.handleErrorMessage(err.message);
      });
  }

  clearMessages() {
    this._successMessagesHandler$.next([]);
    this._messagesHandler$.next([]);
    this._errorsHandler$.next([]);
  }


  private _messagesHandler$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private _successMessagesHandler$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private _errorsHandler$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
}

@Component({ template: '' })
export abstract class BaseForm extends BaseFormErrorHandling implements OnInit {
  form: FormGroup;

  constructor(protected formBuilder: FormBuilder) {
    super();
  }

  ngOnInit() {
    this.prepareForm();
  }

  protected prepareForm() {
    this.form = this.formBuilder.group(
      this.formControlsConfig(),
      this.formOptions()
    );
  }

  protected abstract formControlsConfig(): {
    [key: string]: any;
  };
  protected formOptions(): AbstractControlOptions | null {
    return null;
  }

  protected validLogin<Service extends RestApiService<Service>, Res>(
    service: Service,
    func: (srv: Service) => Promise<Res>
  ) {
    return service.validLogin(func);
  }
}
