import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import { JsonApiError } from '../../rest/response/jsonapi';

@Component({
  selector: 'app-form-messages',
  templateUrl: './form-messages.component.html',
  styleUrls: ['./form-messages.component.scss'],
})
export class FormErrorsComponent implements OnInit, OnDestroy {
  @Input() form: NgForm;
  
  @Input() errors: string[] = [];
  @Input() successMessages: string[] = [];
  @Input() messages: string[] = [];

  constructor() { }

  ngOnInit() {
    if (this.form) {
      this._formStatusChangesSub = this.form.statusChanges.subscribe(status => {
        this.generateErrorMessages(this.form.control);
      });
    }
  }

  ngOnDestroy() {
    this._formStatusChangesSub?.unsubscribe();
  }

  generateErrorMessages(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(controlName => {
      let control = formGroup.controls[controlName];
      let controlErrors = control.errors;
      if (controlErrors === null || controlErrors.count === 0) {
        return;
      }
      // Handle the 'required' case
      if (controlErrors.required) {
        this.errors.push(`${controlName} is required`);
      }
      // Handle 'minlength' case
      if (controlErrors.minlength) {
        this.errors.push(`${controlName} minimum length is ${controlErrors.minlength.requiredLength}.`);
      }
    });
  }

  private _formStatusChangesSub: Subscription;
}