<div *ngIf="errors && errors.length > 0" class="form-error-messages form-errors">
  <ion-list>
    <ion-list-header>
      <ion-label></ion-label>
    </ion-list-header>
    <ion-item *ngFor="let error of errors">
      <ion-text color="danger">{{error}}</ion-text>
    </ion-item>
  </ion-list>
</div>
<div *ngIf="successMessages && successMessages.length > 0" class="form-messages form-success-messages">
  <ion-list>
    <ion-item *ngFor="let successMessage of successMessages">
      <ion-text color="success">{{successMessage}}</ion-text>
    </ion-item>
  </ion-list>
</div>
<div *ngIf="messages && messages.length > 0" class="form-messages">
  <ion-list>
    <ion-item *ngFor="let message of messages">
      <ion-text>{{message}}</ion-text>
    </ion-item>
  </ion-list>
</div>