import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/auth.guard';
import { IosTabletGuard } from './shared/ios-tablet.guard';

const routes: Routes = [
  {
    path: 'treatment-cases',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/treatment-cases/treatment-cases.module').then(
        (m) => m.TreatmentCasesPageModule
      ),
  },
  {
    path: 'archive',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/archive/archive.module').then((m) => m.ArchivePageModule),
  },
  {
    path: 'admin',
    canActivate: [AuthGuard, IosTabletGuard],
    loadChildren: () =>
      import('./pages/admin/admin.module').then((m) => m.AdminPageModule),
  },
  {
    path: 'cms',
    canActivate: [AuthGuard, IosTabletGuard],
    loadChildren: () =>
      import('./pages/cms/cms.module').then((m) => m.CmsPageModule),
  },
  {
    path: 'statistic',
    canActivate: [AuthGuard, IosTabletGuard],
    loadChildren: () =>
      import('./pages/statistic/statistic.module').then(
        (m) => m.StatisticPageModule
      ),
  },
  {
    path: 'data-export',
    canActivate: [AuthGuard, IosTabletGuard],
    loadChildren: () =>
      import('./pages/data-export/data-export.module').then(
        (m) => m.DataExportPageModule
      ),
  },
  {
    path: 'profile',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
  },
    {
    path: 'apu-prozess',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./pages/pdf-view/pdf-view.module').then((m) => m.PdfViewPageModule),
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'about',
    loadChildren: () => import('./pages/about/about.module').then( m => m.AboutPageModule)
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
