import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable, OnDestroy } from "@angular/core";
import { SecureStorage, SecureStorageObject } from '@ionic-native/secure-storage/ngx';
import { Storage } from '@ionic/storage-angular';
import { CookieStorage } from "cookie-storage";

import { AuthToken } from './response/auth-token';
import { Login } from './request/login';

import * as httpConfig from '../../http.config.json';
import { BehaviorSubject, fromEvent, merge, Observable, Subscription } from "rxjs";
import { UserResponse } from "src/app/services/rest/response/user";
import { JsonApiResponse } from "./response/jsonapi";
import { User } from "src/app/core/model/user";
import { UserHelper } from "src/app/services/user-helper.service";
import { NavigationEnd, Router } from "@angular/router";
import { Platform, ToastController } from "@ionic/angular";
import { UserIdleService } from 'angular-user-idle';
import { GlobalConstants } from '../../shared/global.constants';


@Injectable({
    providedIn: 'root'
})
export class AuthService implements OnDestroy {
    private static readonly USE_SECURE_STORAGE = false;
    private static readonly USE_COOKIE_STORAGE = false;

    private readonly httpOptions = {
        headers: new HttpHeaders()
            .append('Content-Type', 'application/json')
            .append('Accept', 'application/json')
            .append('X-API-KEY', httpConfig.apiKey)
    };

    private authHttpOptions(accessToken: string) {
        return {
            headers: new HttpHeaders()
                .append('Content-Type', 'application/json')
                .append('Accept', 'application/json')
                .append('X-API-KEY', httpConfig.apiKey)
                .append('Authorization', `Bearer ${accessToken}`)
        }
    };
    get logout$(): Observable<LogoutParams> {
        return this._logout$.asObservable();
    }
    get loggedout$(): Observable<LogoutParams> {
        return this._loggedout$.asObservable();
    }
    get user$(): Observable<User | null> {
        return this._user$.asObservable();
    }
    get logoutTimer$(): Observable<number> {
        return this._logoutTimer$.asObservable();
    }

    get logoutTimerStart(): Observable<boolean> {
        return this._logoutTimerStart$.asObservable()
    }
    get loginExpiresIn(): number {
        return this._logoutTimer$.value;
    }
    get accessToken(): string {
        return this._accessToken$.value?.accessToken;
    }
    get isSessionExpired(): boolean {
        return this.loginExpiresIn !== undefined && this.loginExpiresIn <= 0;
    }
    get user(): User {
        return this._user$.value;
    }
    get hasToken(): boolean {
        return !!this._accessToken$.value;
    }

    get isUserAdmin() {
        return this.user?.isAdmin;
    }
    get isUserClinicLeader() {
        return this.user?.isClinicLeader;
    }
    get isUserDoctor() {
        return this.user?.isDoctor;
    }
    get isUserStudyAdmin() {
        return this.user?.isStudyAdmin;
    }
    get isUserProjectAssist() {
        return this.user?.isProjectAssist;
    }

    constructor(private http: HttpClient,
        private router: Router,
        private secureStorage: SecureStorage,
        private localStorage: Storage,
        private userHelper: UserHelper,
        public toastController: ToastController,
        private userIdle: UserIdleService,
        private platform: Platform) {
        this._accessTokenSub = this._accessToken$.subscribe(async accessToken => {
            if (accessToken?.accessToken) {
                this.loadUser(accessToken?.accessToken);
            } else if (accessToken?.accessToken === null) {
                this._user$.next(null);
            }
        });
        this._userSub = this._user$.subscribe(user => {
            if (user) {
                this.startLogoutTimer(user);
            }
        });
        this._logoutSub = this._logout$.subscribe(reason => {
            if (reason) {
                this.clearLogin();
                this.stopLogoutTimer();
                this.stopWatching();
                this._loggedout$.emit(reason);
            }
        });
        this._routerEventsSub = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this._currentRoute = event.url;
            }
        });
    }
    ngOnDestroy() {
        this._routerEventsSub?.unsubscribe();
        this._accessTokenSub?.unsubscribe();
        this._userSub?.unsubscribe();
        this._logoutSub?.unsubscribe();
    }

    async init() {
        this.platform.ready().then(() => {
            if (this.platform.is('cordova')){
            this.platform.pause.subscribe(() => {//
                this.stopLogoutTimer()
                this.setItem(AuthService.LAST_IDLE_KEY, new Date().toISOString());
              });
          
              this.platform.resume.subscribe(async () => {// foreground
                this.startLogoutTimer(this.user)
              });
            }
        });
        return this.initStorage().then(() => {
              return this.loadAccessToken();
        });
    }

    public clearLogin() {
        this.setItem(AuthService.ACCESS_TOKEN_KEY, null);
        this.setItem(AuthService.LAST_IDLE_KEY, null);
        this.stopLogoutTimer();
        this.unloadUser();
    }

    public handleAuthError(err: HttpErrorResponse): HttpErrorResponse {
        if (err.status === 401) {
            let reason: LogoutParamReason = undefined;
            if (this.accessToken) {
                reason = (this.isSessionExpired) ? LogoutParamReason.SessionExpired : LogoutParamReason.UserLogin;
            }
            this._logout$.emit({
                reason: reason,
                redirectUri: (reason) ? this._currentRoute : undefined
            });
        } else if (err.status === 403) {
            this.setupToastMessage("Sie haben nicht die erforderlichen Rechte um auf diese Seite zuzugreifen", 5000);
            this.router.navigate(['/profile'], { replaceUrl: true });
        } else if (err.status >= 400 && err.status < 500) {
            console.error("ein Fehler ist aufgetreten", err)
        } else if (err.status == 504 || err.status == 503) {
            this.setupToastMessage("Service nicht erreichbar versuchen sie es später noch einmal.", 5000);
        } else {
            console.error(err);
            this.getErrorMessage(err, 5000);
        }
        return err
    }

    public async login(login: Login): Promise<AuthToken> {
        const authToken = await this.http.post<AuthToken>(httpConfig.baseURL + '/api/auth/token', login, this.httpOptions).toPromise();
        return this.setAccessToken(authToken?.access_token, authToken?.expires_in, new Date()).then(() => authToken);
    }

    public async logout() {
        if (this.accessToken) {
            this.http.post(httpConfig.baseURL + '/api/auth/token/revoke', {}, this.authHttpOptions(this.accessToken));
        }
        this.unloadUser();
        this._logout$.emit({});
    }

    async testLogin() {
        if (!this._accessToken$.value?.accessToken) {
            return false;
        }
        return !!(await this.userMe(this._accessToken$.value?.accessToken));
    }

    private async loadAccessToken() {
        const access_token: string = await this.getItem(AuthService.ACCESS_TOKEN_KEY);
        if (!access_token) {
            this._logout$.emit({});
            return null;
        }
        this._accessToken$.next({ accessToken: access_token });
        return access_token;
    }

    private async startLogoutTimer(user: User) {
        if (!this._idleWatchRunning) {
            if (!user.isUserAdmin) {
                if (!(await this.shouldLogOut())) {
                    this.startWatching();
                } else {
                    this._logoutTimer$.next(null);
                    this._logout$.emit({
                        reason: LogoutParamReason.SessionExpired,
                        redirectUri: this._currentRoute
                    })
                }
            }
        }
    }

    private async shouldLogOut() {
        const log = await this.getItem(AuthService.LAST_IDLE_KEY);
        const lastDate = new Date(log);
        const isToOld = (new Date().getTime() - lastDate.getTime()) / 60000;
        return isToOld > GlobalConstants.timeoutTimeMin
    }

    private stopLogoutTimer() {
        this.stopWatching();
    }

    private setupToastMessage(message: string, duration: number) {
        this.toastController.create({
            message: message,
            duration: duration,
            position: 'top'
        }).then(toast => {
            toast.present();
        });
    }

    private getErrorMessage(err, duration: number = 10000) {
        const messages = err?.error?.errors?.map((error) => error.title);
        messages?.forEach(element => {
            this.setupToastMessage(element, duration)
        });
    }

    private unloadUser() {
        this.setItem(AuthService.ACCESS_TOKEN_KEY, null);
        this.setItem(AuthService.LAST_IDLE_KEY, null);
        this._user$.next(null);
    }

    private async loadUser(accessToken: string): Promise<User> {
        if (accessToken) {
            const user = await this.userMe(accessToken);
            if (user) {
                this._user$.next(user);
                return user;
            }
        }

        this.unloadUser();
        return null;
    }

    private get accessToken$(): Observable<AuthTokenStore> {
        return this._accessToken$.asObservable();
    }

    private async setAccessToken(value: string, expiredIn: number, date: Date) {
        this.setItem(AuthService.ACCESS_TOKEN_KEY, value);
        this.setItem(AuthService.LAST_IDLE_KEY, date?.toISOString());
        this._accessToken$.next({ accessToken: value });
    }
    /*
    private set accessToken(value: string) {
        this.setAccessToken(value);
    }
    */

    private async userMe(accessToken: string): Promise<User> {
        const userResponse: UserResponse = await this.http.get<JsonApiResponse<UserResponse>>(httpConfig.baseURL + '/api/users/me', this.authHttpOptions(accessToken)).toPromise()
            .then(result => result.data);
        return (userResponse) ? await this.userHelper.fromUserResponse(userResponse) : null;
    }

    private async initStorage() {
        if (AuthService.USE_COOKIE_STORAGE) {
            this._cookieStorage = new CookieStorage({
                secure: true,
                sameSite: "Strict"
            });
            return this._cookieStorage;
        } else if (AuthService.USE_SECURE_STORAGE) {
            if (!this._storage) {
                this._storage = await this.secureStorage.create(AuthService.STORAGE_NAME)
            }
            return this._storage;
        }
        if (!this._storage) {
            this._storage = await this.localStorage.create();
        }
        return this._storage;
    }
    private async setItem(key: string, value: string | null) {
        if (AuthService.USE_COOKIE_STORAGE) {
            if (value) {
                return this._cookieStorage?.setItem(key, value);
            }
            return this._cookieStorage?.removeItem(key);
        }

        if (value) {
            return this._storage?.set(key, value);
        }
        return this._storage?.remove(key);
    }
    private async getItem(key: string) {
        if (AuthService.USE_COOKIE_STORAGE) {
            return this._cookieStorage?.getItem(key);
        }

        return this._storage?.get(key);
    }

    stopIdleTimeout() {
        this.userIdle.stopTimer();
        this.restart();
    }

    stopWatching() {
        this._logoutTimerStart$.next(false);
        this._idleFinished?.unsubscribe();
        this._idleTimer?.unsubscribe();
        this._pingTimer?.unsubscribe()
        this.userIdle.stopWatching();
        this.userIdle.resetTimer();
        this._idleWatchRunning = false;

    }

    startWatching() {
        this.userIdle.setCustomActivityEvents(merge(
            fromEvent(window, 'mousemove'), fromEvent(window, 'resize'),
            fromEvent(document, 'keydown'),
            fromEvent(document, 'touchstart'),
            fromEvent(document, 'touchend')));
        this.userIdle.startWatching()
        this._idleWatchRunning = true;
        this._idleTimer = this.userIdle.onTimerStart().subscribe(count => {
            if (count != null) {
                if (!this._logoutTimerStart$.value) {
                    this._logoutTimerStart$.next(true);
                }
                this._logoutTimer$.next(GlobalConstants.userIdleConfig.timeout - count)
            } else {
                this._logoutTimer$.next(count);
                this._logoutTimerStart$.next(false);
            }
        });
        this._idleFinished = this.userIdle.onTimeout().subscribe(() => {
            this._logoutTimerStart$.next(false);
            this._logoutTimer$.next(null);
            this._logout$.emit({
                reason: LogoutParamReason.SessionExpired,
                redirectUri: this._currentRoute
            })
        });
        this._pingTimer = this.userIdle.ping$.subscribe(() => {
            this.setItem(AuthService.LAST_IDLE_KEY, new Date().toISOString());
        });
    }

    restart() {
        this.stopWatching()
        this.startWatching();
    }


    updateAuthenticatedUser(user: User) {
        this._user$.next(user);
    }

    private _storage: SecureStorageObject | Storage;
    private _cookieStorage: CookieStorage;

    private _accessToken$: BehaviorSubject<AuthTokenStore> = new BehaviorSubject(undefined);
    private _user$: BehaviorSubject<User | null> = new BehaviorSubject(undefined);
    private _logout$: EventEmitter<LogoutParams> = new EventEmitter(undefined);
    private _loggedout$: EventEmitter<LogoutParams> = new EventEmitter(undefined);
    private _logoutSub: Subscription;
    private _accessTokenSub: Subscription;
    private _userSub: Subscription;
    private _logoutTimer$: BehaviorSubject<number> = new BehaviorSubject(undefined);
    private _logoutTimerStart$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private _routerEventsSub: Subscription;
    private _currentRoute: string

    private _idleTimer: Subscription;
    private _pingTimer: Subscription;
    private _idleFinished: Subscription;
    private _idleWatchRunning: boolean = false;
    private static readonly STORAGE_NAME = 'login';
    private static readonly ACCESS_TOKEN_KEY = 'access_token';
    private static readonly LAST_IDLE_KEY = 'last_idle';
}
interface AuthTokenStore {
    accessToken: string;
}

export enum LogoutParamReason {
    None = 0,
    SessionExpired = 1,
    UserLogin = 2
}
export interface LogoutParams {
    reason?: LogoutParamReason;
    redirectUri?: string;
}