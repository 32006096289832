import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Platform, MenuController, ModalController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { GlobalConstants } from './shared/global.constants';
import { MainMenuComponent } from './core/main-menu/main-menu.component';
import { AuthService } from './core/rest/auth.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NetworkHelperService } from './services/network-helper.service';
import { OfflineComponent } from './pages/shared/offline/offline.component';
import { TimeoutComponent } from './pages/shared/timout/timeout.component';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit, AfterViewInit {
  public menuId = MainMenuComponent.Id;
  public title = 'APU';
  private logoutTimerStartSub: Subscription;

  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private splashScreen: SplashScreen,
    private menuController: MenuController,
    private router: Router,
    private authService: AuthService,
    private networkHelper: NetworkHelperService,
    private modalController: ModalController
  ) { }

  ngOnInit() {
    this._logoutSub = this.authService.logout$.subscribe((params) => {
      if (params) {
        this.authService.clearLogin();
        return this.router.navigate(['/login'], { replaceUrl: true, queryParams: params });
      }
    });

    this.initializeApp();
  }

  ngOnDestroy() {
    this._logoutSub?.unsubscribe();
    this.detachNetworkListener();
    this.logoutTimerStartSub.unsubscribe()
  }

  public async initializeApp() {
    return this.platform.ready().then(async () => {
      this.statusBar.overlaysWebView(false);
      this.statusBar.backgroundColorByHexString('#1b4044');
      this.statusBar.styleLightContent();
      this.splashScreen.hide();
      this.menuController.enable(true, this.menuId);
      this.attachNetworkListener()
      if (window.innerWidth > GlobalConstants.tabletBreakpoint) {
        this.menuController.open(this.menuId);
        this.menuController.swipeGesture(false);
      }
    });
  }

  ngAfterViewInit() {
    this.authService.init();
    let isShown = false
    this.logoutTimerStartSub = this.authService.logoutTimerStart.subscribe(async showTimeout => {
      if (showTimeout && !isShown) {
        const modal = await this.modalController.create({
          component: TimeoutComponent,
          swipeToClose: false,
          backdropDismiss: false
        });
        modal.present()
        isShown = true;
        modal.onDidDismiss().then(() => isShown = false);
      }
    })
  }


  attachNetworkListener() {
    this.networkHelper.startListeningConnection();
    let modal: HTMLIonModalElement;
    let isShown = false
    this.networkHelper.getConnected().subscribe(async isConnected => {
      if (!isConnected && !isShown) {
        modal = await this.modalController.create({
          component: OfflineComponent,
          swipeToClose: false,
          backdropDismiss: false
        });
        modal.present();
        isShown = true;
        modal.onDidDismiss().then(() => isShown = false);
      }
    });
  }

  detachNetworkListener() {
    this.networkHelper.stopListeningConnection();
    this.networkHelper.getConnected().unsubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (
      window.innerWidth > GlobalConstants.tabletBreakpoint ||
      this.platform.isLandscape()
    ) {
      this.menuController.open(this.menuId);
      this.menuController.swipeGesture(false);
    } else {
      this.menuController.swipeGesture(true);
    }
  }

  private _logoutSub: Subscription;
}
