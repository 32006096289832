import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { AlertController, Platform } from '@ionic/angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IosTabletGuard implements CanActivate {
  constructor(
    private platform: Platform,
    private alertController: AlertController,
    private router: Router
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const isIpad = this.platform.is('ipad');
    if (isIpad) {
      this.presentAlert();
      return false;
    }
    return true;
  }

  async presentAlert() {
    const alert = await this.alertController.create({
      header: 'Diese Funktion steht Ihnen in der Web-Version zur Verfügung.',
      message: 'Bitte loggen Sie sich in der Web-Version von APU an.',
      buttons: [
        {
          text: 'Schließen',
          handler: () => this.router.navigate(['/profile'], { replaceUrl: true }),
        },
      ],
    });

    alert.present();
  }
}
