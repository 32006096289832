import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { User } from 'src/app/core/model/user';
import { MenuHelper } from 'src/app/services/menu-helper.service';
import { GlobalConstants } from 'src/app/shared/global.constants';
import { LogoutParamReason, LogoutParams } from '../../core/rest/auth.service';
import { UserLoginPage } from '../../core/user/login/user-login.page';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage {
  
  constructor(
    private modalController: ModalController,
    private router: Router,
    private route: ActivatedRoute,
    private menuHelper: MenuHelper,
    private LoginService: LoginService
  ) { }

  ngOnInit() {
  }

  ionViewDidEnter() {
    this._queryParamsSub = this.route.queryParams.subscribe(async queryParams => {
      const params: LogoutParams = {
        reason: (queryParams['reason']) ? parseInt(queryParams['reason']) as LogoutParamReason : LogoutParamReason.None,
        redirectUri: queryParams['redirectUri'],
      }
      const timeout = GlobalConstants.timeoutTimeMin;
      let extraMessage = undefined;
      switch (params.reason) {
        case LogoutParamReason.None:
          extraMessage = undefined;
          break;
        case LogoutParamReason.SessionExpired:
          extraMessage = `Sie wurden aus Sicherheitsgründen automatisch ausgeloggt, weil sie während der Session ${timeout} Minuten inaktiv gewesen sind. Bitte melden Sie sich erneut mit Ihren Benutzerdaten an, um fortzufahren.`;
          break;
        case LogoutParamReason.UserLogin:
          extraMessage = 'Sie wurden aus Sicherheitsgründen automatisch ausgeloggt, weil ihre Authentifizierung ausgelaufen ist. Bitte melden Sie sich erneut mit Ihren Benutzerdaten an, um auf diesem Gerät fortzufahren.';
          break;
      }
      const top = await this.modalController.getTop()
      if (top) { this.modalController.dismiss(); }

      this._loginModal = await this.modalController.create({
        component: UserLoginPage,
        swipeToClose: false,
        backdropDismiss: false,
        componentProps: {
          extraMessage: extraMessage,
          redirectUri: params.redirectUri,
          isModal: true
        },
      });
      this._loginModal.present();
      const { data } = await this._loginModal.onWillDismiss();
      this._loginModal = null;
      if (data) {
        const user = data as User;
        if (params?.redirectUri && this.LoginService.canVisitPageURL(params?.redirectUri, user)) {
          this.router.navigate([params?.redirectUri], { replaceUrl: true });
        } else {
          const menu = this.menuHelper.createMenu(user);
          const redirectUri = (menu.length > 0) ? menu[0].url : '/';
          this.router.navigate([redirectUri], { replaceUrl: true });
        }
      }
    });
  }

  ionViewWillLeave() {
    this._queryParamsSub?.unsubscribe();
    this._loginModal?.dismiss();
  }

  private _loginModal: HTMLIonModalElement;
  private _queryParamsSub: Subscription;
}
