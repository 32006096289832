import { Injectable } from '@angular/core';
import { Network } from '@ionic-native/network/ngx';
import { Subscription, BehaviorSubject, fromEvent } from 'rxjs';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class NetworkHelperService {
  private connectSubscription : Subscription;
  private disconnectSubscription: Subscription;
  getConnected() {
    return this._isConnected;
  }
  private _isConnected: BehaviorSubject<Boolean> = new BehaviorSubject(true);
    constructor(private network: Network, private platform: Platform) {}

  startListeningConnection() {
  if(this.platform.is('cordova')) {
  this.connectSubscription = this.network.onConnect().subscribe(() => {
    setTimeout(() => {
      this._isConnected.next(true);
    }, 3000);
  });

  this.disconnectSubscription = this.network.onDisconnect().subscribe(() => {
    this._isConnected.next(false);
  });
  } else {
      fromEvent(window, 'online').subscribe(isOnline => {
        this._isConnected.next(true);
      });
      fromEvent(window, 'offline').subscribe(isOffline => {
        this._isConnected.next(false);
      });
  }   
}

stopListeningConnection() {
  this.connectSubscription?.unsubscribe();
  this.disconnectSubscription?.unsubscribe();
}

}
