import { UserResponse } from "../../services/rest/response/user";

export enum UserSex {
  Female = 'female',
  Male = 'male',
  Divers = 'divers',
  Unknown = 'undefined'
}
export enum UserRole {
  Admin = 'admin',
  StudyAdmin = 'study_admin',
  ProjectAssist = 'project_assist',
  ClinicLeader = 'clinic_leader',
  Doctor = 'doctor',
}
export class User {

  get id(): string {
    return this._id;
  }
  get firstname(): string {
    return this._firstname;
  }
  get surname(): string {
    return this._surname;
  }
  get sex(): UserSex {
    return this._sex;
  }
  get birthday(): Date {
    return this._birthday;
  }
  get username(): string {
    return this._username;
  }
  get role(): UserRole {
    return this._role;
  }
  get roleName(): string {
    return this._roleName;
  }
  get clientId(): string {
    return this._clientId;
  }
  get clientName(): string {
    return this._clientName;
  }
  get isBlocked(): boolean {
    return this._status != "published";
  }

  get status(): string {
    return this._status
  }

  get age(): number | null {
    if (this.birthday) {
      const ageMs = Date.now() - this.birthday.getTime();
      const ageDate = new Date(ageMs);
      return Math.abs(ageDate.getUTCFullYear() - 1970);
    }
    return null;
  }

  get displayName(): string {
    return `${this.firstname} ${this.surname}`.trim();
  }

  get isAdmin() {
    return this.role === UserRole.Admin;
  }
  get isClinicLeader() {
    return this.role === UserRole.ClinicLeader;
  }
  get isDoctor() {
    return this.role === UserRole.Doctor;
  }
  get isStudyAdmin() {
    return this.role === UserRole.StudyAdmin;
  }
  get isProjectAssist() {
    return this.role === UserRole.ProjectAssist;
  }

  get hasClient(): boolean {
    return !!this._clientId;
  }

  get isUserAdmin() {
    return this.role === UserRole.Admin;
  }
  get isUserClinicLeader() {
    return this.role === UserRole.ClinicLeader;
  }
  get isUserDoctor() {
    return this.role === UserRole.Doctor;
  }

  constructor(
    private _id: string,
    private _firstname: string,
    private _surname: string,
    private _sex: UserSex,
    private _birthday: Date,
    private _username: string,
    private _role: UserRole,
    private _roleName: string,
    private _clientId: string,
    private _clientName: string,
    private _status: string,
  ) { }
}
