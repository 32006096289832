import { MenuEntry } from './menu-entry';
import { UserRole } from '../core/model/user';
import { version } from '../../../package.json';

export interface PagePermission {
  roles: UserRole[];
  path: string;
  menuEntry?: MenuEntry;
}

export enum MenuUrls {
  TreatmentCases = '/treatment-cases',
  Archive = '/archive',
  Admin = '/admin',
  Statistic = '/statistic',
  CMS = '/cms',
  DataExport = '/data-export',
  Profile = '/profile',
  Prozess= '/apu-prozess',
  About = '/about'
}
export class GlobalConstants {
  public static Version = version;
  public static tabletBreakpoint = 960;
  public static laptopBreakpoint = 1300;
  public static mobileBreakpoint = 450;
  // 15 mins = 900 sec, 3 mins = 180 secs , 720 = 12 mins
  public static userIdleConfig = {idle: 720, timeout: 180 , ping: 10};
  // timeout in minutes.
  public static timeoutTimeMin = 15;
  public static pagePermissions: PagePermission[] = [
    {
      roles: [UserRole.ClinicLeader, UserRole.Doctor],
      path: 'treatment-cases',
      menuEntry: {
        title: 'Fälle',
        url: MenuUrls.TreatmentCases,
        icon: 'fitness-outline',
        matchPath: /\/treatment-cases\/?.*/,
      },
    },
    {
      roles: [UserRole.ClinicLeader, UserRole.Doctor],
      path: 'treatment-cases/decision/:treatmentcase_id/:decision_id',
    },
    {
      roles: [UserRole.ClinicLeader, UserRole.Doctor],
      path: 'treatment-cases/decisions/flowchart/:treatmentcase_decision_id',
    },
    {
      roles: [UserRole.ClinicLeader, UserRole.Doctor],
      path: 'archive',
      menuEntry: {
        title: 'Archiv',
        url: MenuUrls.Archive,
        icon: 'file-tray-stacked-outline',
        matchPath: /\/archive\/?.*/,
      },
    },
    {
      roles: [UserRole.ClinicLeader, UserRole.Doctor],
      path: 'archive/decision/:treatmentcase_id/:decision_id',
    },
    {
      roles: [UserRole.ClinicLeader, UserRole.Doctor],
      path: 'archive/decisions/flowchart/:treatmentcase_decision_id',
    },
    {
      roles: [UserRole.Admin, UserRole.ClinicLeader],
      path: 'admin',
      menuEntry: {
        title: 'Admin Konsole',
        url: MenuUrls.Admin,
        icon: 'business-outline',
        matchPath: /\/admin\/?.*/,
      },
    },
    {
      roles: [UserRole.Admin, UserRole.ClinicLeader, UserRole.StudyAdmin],
      path: 'statistic',
      menuEntry: {
        title: 'Statistik',
        url: MenuUrls.Statistic,
        icon: 'stats-chart-outline',
        matchPath: /\/statistic\/?.*/,
      },
    },
    {
      roles: [UserRole.Admin],
      path: 'cms',
      menuEntry: {
        title: 'CMS',
        url: MenuUrls.CMS,
        icon: 'construct-outline',
        matchPath: /\/cms\/?.*/,
      },
    },
    {
      roles: [UserRole.Admin],
      path: 'cms/decision/flowchart/:eepk_id',
    },
    {
      roles: [UserRole.Admin],
      path: 'cms/decision/:eepk_id/:decision_id',
    },
    {
      roles: [UserRole.StudyAdmin, UserRole.ProjectAssist],
      path: 'data-export',
      menuEntry: {
        title: 'Datenexport',
        url: MenuUrls.DataExport,
        icon: 'download-outline',
        matchPath: /\/data-export\/?.*/,
      },
    },
    {
      roles: [
        UserRole.Admin,
        UserRole.StudyAdmin,
        UserRole.ProjectAssist,
        UserRole.ClinicLeader,
        UserRole.Doctor,
      ],
      path: 'profile',
      menuEntry: {
        title: 'Profile',
        url: MenuUrls.Profile,
        icon: 'person-outline',
        matchPath: /\/profile\/?.*/,
      },
    },
    {
      roles: [
        UserRole.ClinicLeader,
        UserRole.Doctor,
      ],
      path: 'apu-prozess',
      menuEntry: {
        title: 'APU-Prozess',
        url: MenuUrls.Prozess,
        icon: 'book-outline',
        matchPath: /\/apu-prozess\/?.*/,
      },
    },
    {
      roles: [
        UserRole.Admin,
        UserRole.StudyAdmin,
        UserRole.ProjectAssist,
        UserRole.ClinicLeader,
        UserRole.Doctor,
      ],
      path: 'about',
      menuEntry: {
        title: 'Über uns',
        url: MenuUrls.About,
        icon: 'ellipsis-horizontal-outline',
        matchPath: /\/about\/?.*/,
      },
    },
  ];
}
