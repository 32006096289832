
import { Validators } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Component, Input, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { BaseForm } from '../../../errorhandling/BaseForm';
import { AuthService } from '../../../rest/auth.service';
import { Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-user-login-form',
  templateUrl: 'user-login.component.html',
  styleUrls: ['./user-login.component.scss']
})
export class UserLoginFormComponent extends BaseForm implements OnDestroy {
  @Input() isModal: boolean;
  @Input() extraMessage: string;
  @Input() redirectUri: string;

  constructor(formBuilder: FormBuilder, private modalController: ModalController, private authService: AuthService) {
    super(formBuilder);
  }

  ngOnInit() {
    this._loginSub = this.authService.user$.subscribe(user => {
      if (user) {
        if (this.isModal) {
          this.modalController.dismiss(user);
        }
      }
    });

    super.ngOnInit();
    if (this.extraMessage) {
      this.handleMessages([this.extraMessage]);
    }
  }

  ngOnDestroy() {
    this._loginSub?.unsubscribe();
  }

  formControlsConfig() {
    return {
      username: ['', Validators.required],
      password: ['', Validators.required],
    };
  }

  public onLogin() {
    const data = {
      username: this.form.value.username,
      password: this.form.value.password
    };

    this.authService.login(data)
      .then(authToken => {
        this.form?.patchValue({
          username: '',
          password: ''
        });
      })
      .catch(error => {
        this.handleHttpError(error);
        this.form?.patchValue({
          password: ''
        });
      });
  }

  private _loginSub: Subscription;
}
