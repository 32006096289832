<ion-menu animated="false" side="start" swipeGesture="true" menuId="main-menu" persistent="true"
  contentId="main-content">
  <ion-header no-border>
    <ion-toolbar color="primary" class="main-menu-toolbar ion-justify-content-center">
      <ion-avatar slot="start" [ngClass]="
          title
            ? 'text-center toolbar-avatar-center ion-margin-end'
            : 'ion-margin-end'
        ">
        <img [src]="icon" alt="Menu Icon" />
      </ion-avatar>
      <div class="ion-justify-content-center toolbar-text ion-text-center">
        <ion-label class="
            ion-margin-start
            ion-text-center
            ion-margin-top
            ion-align-self-center
          ">{{ title }}</ion-label>
      </div>
    </ion-toolbar>
  </ion-header>
  <ion-content padding class="main-menu-content" color="primary">
    <ion-list class="menu-item-list ion-no-padding" *ngIf="menu$ | async as menu">
      <ion-item detail="false" lines="none" *ngFor="let menu_enitry of menu; let i = index"
        [routerLink]="menu_enitry.url" [class.selected]="menu_enitry.matchPath.test(currentRoute)">
        <ion-grid>
          <ion-row>
            <ion-col class="ion-align-items-center">
              <ion-icon class="menu-item-image" [name]="menu_enitry.icon"></ion-icon>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col class="ion-align-items-center">
              <ion-label class="menu-title">{{ menu_enitry.title }}</ion-label>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
    </ion-list>
  </ion-content>
  <ion-footer class="ion-no-border ion-no-padding ion-no-margin" *ngIf="user$ | async as user">
    <ion-item no-lines color="primary">
      <ion-text class="ion-text-center ion-margin-top ion-align-self-center">
        Angemeldet als {{ userDisplayName }}
      </ion-text>
    </ion-item>
    <ion-toolbar class="ion-no-padding ion-no-margin">
      <ion-button
        class="ion-no-padding"
        color="secondary"
        expand="full"
        (click)="onLogout()"
        >Abmelden</ion-button
      >
    </ion-toolbar>
  </ion-footer>
</ion-menu>
