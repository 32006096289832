import {
  Component,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { MainMenuComponent } from '../main-menu/main-menu.component';
import { GlobalConstants } from 'src/app/shared/global.constants';
import {
  ActionSheetController,
  AlertController,
  ModalController,
  Platform,
} from '@ionic/angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, OnDestroy {
  public menuId = MainMenuComponent.Id;
  @Input() title: string;
  @Input() enableBackbutton: boolean = false;
  @Input() isModal: boolean = false;

  @Input() enableCloseButton = false;
  @Input() closeButtonIcon: String;

  @Input() showMenuToggle: boolean = false;
  @Input() actionButton: string;
  @Input() patientName: string;
  @Input() menuButtons: MenuButton[] = [];
  @Input() showAllButtons: boolean = false;

  buttonAction: any;
  isPotrait: boolean;
  isWideScreen: boolean = window.innerWidth > GlobalConstants.tabletBreakpoint;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isWideScreen = window.innerWidth > GlobalConstants.tabletBreakpoint;
    this.isPotrait = this.platform.isPortrait();
  }

  constructor(
    private actionSheetController: ActionSheetController,
    private modalController: ModalController,
    private alertController: AlertController,
    private platform: Platform
  ) {}

  ngOnInit() {
    this.isPotrait = this.platform.isPortrait();
    if (this.isModal) {
      this.showMenuToggle = false;
      this.menuButtons = !this.menuButtons?.length
        ? [
            {
              icon: 'close-outline',
              type: ButtonType.CloseButtonAction,
              action: () => this.onClose(),
              showIconOnly: true,
            },
          ]
        : this.menuButtons;
    }
  }
  ngOnDestroy() {
    this._closeSub?.unsubscribe();
  }

  async presentActionSheet(button: MenuButton[]) {
    const actionSheet = await this.actionSheetController.create({
      header: 'Mehr',
      buttons: this.createButton(button),
    });
    await actionSheet.present();
  }

  createButton(menuButtons: MenuButton[]) {
    let buttons = [];
    for (let item of menuButtons) {
      let actionSheetButton = {
        text: item.title,
        cssClass: this.shouldDisplayButtonsFor(item.title),
        icon: item.icon,
        disabled: true,
        handler: () => {
          this.didClickMenuAction(item);
        },
      };
      buttons.push(actionSheetButton);
    }
    return buttons;
  }

  didClickMenuAction(button: MenuButton) {
    if (button.disabled) {
      this.presentAlertDisabled();
      return;
    }
    button.action();
  }

  async presentAlertDisabled() {
    const alert = await this.alertController.create({
      header: 'Der Action ist disabled',
      message: 'Der eEPK ist final oder kein Button ist ausgewählt',
      buttons: [
        {
          text: 'Schließen',
        },
      ],
    });

    await alert.present();
  }

  private shouldDisplayButtonsFor(item) {
    return item ? '' : 'ion-hide';
  }

  private onClose() {
    return this.modalController
      .getTop()
      .then((v) => (v ? this.modalController.dismiss() : null));
  }
  private _closeSub: Subscription;
}

export enum ButtonType {
  CloseButtonAction,
  PatientButtonAction,
  MenuAction,
}

export class MenuButton {
  title?: string;
  type: ButtonType;
  action?: any;
  icon?: string;
  color?: string = 'secondary';
  slot?: string = '';
  showIconOnly?: boolean = false;
  disabled?: boolean = false;
}
