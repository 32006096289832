import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { SharedModule } from 'src/app/shared/shared.module';

import { TimeoutComponent } from './timeout.component';
import { TimeoutPageRoutingModule } from './timeout-routing.module';
import { PagesSharedModule } from '../shared-pages.module';
import { MinuteSecondsPipe } from '../../../shared/MinuteSeconds.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TimeoutPageRoutingModule,
    SharedModule,
    PagesSharedModule,
  ],
  declarations: [TimeoutComponent, MinuteSecondsPipe],
})
export class TimeoutPageModule {}
