import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { LoginPageRoutingModule } from './login-routing.module';

import { LoginPage } from './login.page';
import { UserLoginPageModule } from '../../core/user/login/user-login.module';
import { PagesSharedModule } from '../shared/shared-pages.module';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    LoginPageRoutingModule,
    PagesSharedModule,
    SharedModule,
    UserLoginPageModule
  ],
  declarations: [LoginPage]
})
export class LoginPageModule {}
