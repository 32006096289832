import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthService } from '../../../core/rest/auth.service';
import { GlobalConstants } from '../../../shared/global.constants';

@Component({
  selector: 'app-timeout',
  templateUrl: './timeout.component.html',
  styleUrls: ['./timeout.component.scss'],
})
export class TimeoutComponent implements OnInit {
  countDown: number = GlobalConstants.userIdleConfig.timeout - GlobalConstants.userIdleConfig.idle - 2 ;
  constructor(private modalController: ModalController, private autService: AuthService) { }

  ngOnInit() {
    this.autService.logoutTimer$.subscribe(counter => {
        this.countDown = counter
    })
  }

  dismiss() {
    this.autService.stopIdleTimeout();
    this.modalController.dismiss();
  }

}
