import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { UserLoginPageRoutingModule } from './user-login-routing.module';

import { UserLoginPage } from './user-login.page';
import { PagesSharedModule } from '../../../pages/shared/shared-pages.module';
import { UserLoginFormComponent } from './user-login-form/user-login.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    UserLoginPageRoutingModule,
    PagesSharedModule,
    SharedModule
  ],
  declarations: [UserLoginPage, UserLoginFormComponent],
  exports: [UserLoginFormComponent]
})
export class UserLoginPageModule {}
