import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FormErrorsComponent } from '../core/errorhandling/form-messages/form-messages.component';
import { ToolbarComponent } from '../core/toolbar/toolbar.component';

@NgModule({
  imports: [CommonModule, IonicModule],
  declarations: [ToolbarComponent, FormErrorsComponent],
  exports: [ToolbarComponent, FormErrorsComponent],
})
export class SharedModule {}
