import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from 'src/app/shared/shared.module';
import { CmsDetailsComponent } from '../cms/cms-details/cms-details.component';
import { EssentialDataComponent } from './essential-data/essential-data.component';
import { NotAvailiableComponent } from './not-availiable/not-availiable.component';
import { PoolClientItemComponent } from './pool-client-item/pool-client-item.component';
import { PoolCmsItemComponent } from './pool-cms-item/pool-cms-item.component';
import { PoolDataExportItemComponent } from './pool-data-export-item/pool-data-export-item.component';
import { PoolTreatmentCaseItemComponent } from './pool-treatment-case-item/pool-treatment-case-item.component';
import { PoolUserItemComponent } from './pool-user-item/pool-user-item.component';
import { UserFormComponent } from './user-form/user-form.component';
import { OfflineComponent } from './offline/offline.component';
import { PaginationComponent } from './pagination/pagination.component';

@NgModule({
  imports: [CommonModule, IonicModule, ReactiveFormsModule, SharedModule],
  declarations: [
    UserFormComponent,
    PoolUserItemComponent,
    PoolClientItemComponent,
    PoolTreatmentCaseItemComponent,
    PoolCmsItemComponent,
    EssentialDataComponent,
    NotAvailiableComponent,
    OfflineComponent,
    PoolDataExportItemComponent,
    CmsDetailsComponent,
    PaginationComponent
  ],
  exports: [
    UserFormComponent,
    PoolUserItemComponent,
    PoolClientItemComponent,
    PoolTreatmentCaseItemComponent,
    PoolCmsItemComponent,
    EssentialDataComponent,
    NotAvailiableComponent,
    OfflineComponent,
    PoolDataExportItemComponent,
    CmsDetailsComponent,
    PaginationComponent
  ],
})
export class PagesSharedModule {}
