import { Injectable } from '@angular/core';
import { MenuEntry } from '../shared/menu-entry';
import { User} from '../core/model/user';
import { GlobalConstants, MenuUrls } from '../shared/global.constants';

@Injectable({
  providedIn: 'root',
})
export class MenuHelper {
  constructor() { }

  createMenu(user: User): MenuEntry[] {
    if (!user) {
      return [];
    }

    let ret: MenuEntry[] = [];

    const menuPagesUrls = Object.values(MenuUrls);
    for (const menuUrl of menuPagesUrls) {
      for (const pagePermission of GlobalConstants.pagePermissions) {
        if (
          pagePermission.menuEntry &&
          pagePermission.menuEntry.matchPath.test(menuUrl) &&
          pagePermission.roles.includes(user.role)
        ) {
          ret.push(pagePermission.menuEntry);
        }
      }
    }

    return ret;
  }
}
