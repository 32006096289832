import { Injectable } from '@angular/core';
import { User, UserRole, UserSex } from '../core/model/user';
import { UserResponse } from './rest/response/user';

@Injectable({
  providedIn: 'root',
})
export class UserHelper {
  constructor() { }

  async fromUserResponse(user: UserResponse): Promise<User> {
    if (!user) {
      return null;
    }

    return new User(
      user?.id,
      user?.profile?.firstname ?? '', user?.profile?.surname ?? '',
      user?.profile?.sex as UserSex,
      (user?.profile?.birthday) ? new Date(user.profile.birthday) : null,
      user?.username,
      user?.role?.name as UserRole, user.role?.display_name,
      user?.client?.id, user?.client?.name,
      user?.status
    );
  }
}
